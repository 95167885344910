// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-music-js": () => import("./../../../src/templates/Music.js" /* webpackChunkName: "component---src-templates-music-js" */),
  "component---src-templates-videos-js": () => import("./../../../src/templates/Videos.js" /* webpackChunkName: "component---src-templates-videos-js" */)
}

